import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, value }) => ({
    height: 7,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor:
      // theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8',
      ...(value && {
        backgroundColor:
          value <= 20
            ? '#ee8d20'
            : value <= 40
            ? '#FFA500'
            : value <= 60
            ? '#FFD700'
            : value <= 80
            ? '#32CD32'
            : '#008000',
      }),
    },
  })
);
