import { Box } from '@mui/material';
import React from 'react';
import serviceCards from '#/components/pages/Dashboard/serviceCards';
import DashboardCard from '#/components/shared/dashboard-card';
import CardExtras from '#/components/pages/Dashboard/extras/card-extras';
import useAuth from '#/hooks/useAuth';

export default function ServicesList() {
  const { user } = useAuth();

  const getAvailableServices = () => {
    const orderedKeys = [
      'power_of_attorney',
      'living_will',
      'last_will',
      'digital_footprint',
      'register_of_assets',
      'tax_folder',
    ];
    const baseServices = orderedKeys
      .map((key) => serviceCards.find((card) => card.serviceKey === key))
      .filter(Boolean) as typeof serviceCards;

    // Get the civil status specific card
    const civilStatusCard =
      user?.civil_status === 'married'
        ? serviceCards.find((card) => card.serviceKey === 'marriage_contract')
        : serviceCards.find(
            (card) => card.serviceKey === 'cohabitation_agreement'
          );

    const lastWillIndex = baseServices.findIndex(
      (card) => card.serviceKey === 'last_will'
    );
    if (lastWillIndex !== -1 && civilStatusCard) {
      baseServices.splice(lastWillIndex + 1, 0, civilStatusCard);
    }

    return baseServices;
  };

  const availableServices = getAvailableServices();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 2,
      }}
    >
      {availableServices.map((card, i) => (
        <DashboardCard
          key={`${card.title + i}`}
          title={card.title}
          icon={card.icon}
          description={card.description}
          estimate={card.estimate}
          actions={
            <CardExtras
              serviceKey={card.serviceKey as any}
              extras={{
                toServiceURL: card.url as string,
                progress: card.progressive,
              }}
            />
          }
        />
      ))}
    </Box>
  );
}
